import Config from '../config.json'
import { useState, useEffect } from 'react'
import { ethers } from "ethers"
import { Col, Row } from 'react-bootstrap'

import SearchBar from '../components/SearchBar'
import Dashboard from '../components/Dashboard'

import LatestBlocks from '../components/LatestBlocks'
import LatestTransactions from '../components/LatestTransactions'

//const axios = require('axios').default;
import axios from 'axios';
const Web3 = require('web3');
const web3 = new Web3('https://rpc.siftoscan.com/');

const Home = ({ networkName, account }) => {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])

    const [txs, setTxs] = useState([])
    const [current_supply, setCurrent_supply] = useState([])
    const [pendingTxs, setPendingTxs] = useState([])
    const [lastBlock, setLastBlock] = useState(0)
    const [pendingBlocks, setPendingBlocks] = useState([])

    const [searchValue, setSearchValue] = useState('')
    const [searchFilter, setSearchFilter] = useState('all')
    const [searchAccount, setSearchAccount] = useState('')

    const [stats, setStats] = useState({
        dailytxnfee: 0,
        dailynetutilization: 0,
        avgdifficulty: 0,
        avgtxnperblock: 0,
        avgtxnperday: 0,
        avgtxnperhour: 0,
        avgtxnperminute: 0
    })

    const getLatestTransactions = async () => {
        try {
            const response = await axios.get(Config.restAPI + 'latest_transection_list');
            //console.log("Response:", response.data.data);setCurrent_supply
            setTxs(response.data.data);
            // Handle response data
        } catch (error) {
            console.error("Error fetching latest transactions:", error);
            // Handle error
        }
    };



    const current_Supply = async () => {
        try {
            const response = await axios.get('https://blockchainapi.siftoscan.com/data_api/surrent_supply_api');
           // console.log("current_amount:", response.data.current_amount);
            setCurrent_supply(response.data.current_amount);
            // Handle response data
        } catch (error) {
            console.error("Error fetching latest transactions:", error);
            // Handle error
        }
    };


    const insertTransactions = async () => {
        try {
            const response = await axios.get('https://blockchainapi.siftoscan.com/data_api/transactions/latest');
            console.log("insertTransactions");
           // setTxs(response.data.data);
            // Handle response data
        } catch (error) {
            console.error("Error fetching latest transactions:", error);
            // Handle error
        }
    };

   
    
    
    
    
    
    

    //subscribe to new blocks with ethers.js
    const getLatestBlocks = async () => {

        let provider = new ethers.providers.JsonRpcProvider(Config.node);

        //verify if metamask is connected
        if (window.ethereum) {
            provider = new ethers.providers.JsonRpcProvider(Config.node);
        }

        const blockNumber = await provider.getBlockNumber()

        if ( lastBlock === 0) {
            setLastBlock(blockNumber - 11)

        } else {
            if ( lastBlock < blockNumber ) {
                for (let i = lastBlock+1; i < blockNumber; i++) {

                    const block = await provider.getBlock(i+1)

                    //skip if block is in items
                    if ( items.find(item => item.number === block.number) ) {
                        continue
                    }

                    setLastBlock(i)

                    //block exec time
                    let lastTS = 0
                    if (items.length > 0) {
                        lastTS = items[0].timestamp || 0
                    } else {
                        const lblock = await provider.getBlock(i)
                        lastTS = lblock.timestamp
                    }
                    block.duration = block.timestamp - lastTS
                    items.unshift(block)

                    // remove oldest item if we have more than 10 items
                    if (items.length > 10) {
                        items.pop()
                    }
                }


                //for each item is items echo to console
                items.forEach(item => {
                    item.timediff = Math.round(+new Date()/1000) - item.timestamp
                })

                setItems(items)
            }
        }
    }

    const getLatest = async () => {

        let provider = new ethers.providers.JsonRpcProvider(Config.node);

        //verify if metamask is connected
        if (window.ethereum) {
            provider = new ethers.providers.JsonRpcProvider(Config.node);
        }


        const blockNumber = await provider.getBlockNumber()

        //console.log(blockNumber)


       //console.log('new get latest', blockNumber)

        if ( lastBlock === 0) {
            if ( blockNumber > 10 ) {
                setLastBlock(blockNumber - 11)
            } else {
                setLastBlock(-1)
            }
        } else {
            if ( lastBlock < blockNumber ) {
                for (let i = lastBlock+1; i < blockNumber; i++) {

                    const block = await provider.getBlock(i+1)

                    //skip if block is in items
                    if ( items.find(item => item.number === block.number) ) {
                        continue
                    }

                    setLastBlock(i)

                    // if block has transactions, get them
                    // if (block.transactions.length > 0) {
                    //     block.transactions.forEach(async (tx) => {

                    //         //if tx is not in txs, add it
                    //         if ( txs.length === 0 || (!txs.find(item => item.hash === tx) )) {

                    //             const txData = await provider.getTransactionReceipt(tx)
                    //             console.log("txData",txData);
                    //             //txData.method = txData.data.slice(0, 10)
                    //             txData.hash = tx
                    //             txData.value = parseInt(txData.value)
                    //             txData.receipt = {
                    //                 status: txData.status,
                    //                 gasUsed: parseInt(txData.gasUsed).toString()
                    //             }
                    //             txs.unshift(txData)

                    //             //if txs is more than 10, remove oldest
                    //             if (txs.length > 10) {
                    //                 txs.pop()
                    //             }

                    //             setTxs(txs)
                    //         }
                    //     })

                    // }


                    //block exec time
                    let lastTS = 0
                    if (items.length > 0) {
                        lastTS = items[0].timestamp || 0
                    } else {
                        const lblock = await provider.getBlock(i)
                        lastTS = lblock.timestamp
                    }
                    block.duration = block.timestamp - lastTS
                    items.unshift(block)

                    // remove oldest item if we have more than 10 items
                    if (items.length > 10) {
                        items.pop()
                    }
                }


                //for each item is items echo to console
                items.forEach(item => {
                    item.timediff = Math.round(+new Date()/1000) - item.timestamp
                })

                setItems(items)
            }
        }
    }




   

    

    const getStats = async () => {
        let stats = {}
        // let provider = new ethers.providers.JsonRpcProvider(Config.node);

        // //verify if metamask is connected
        // if (window.ethereum) {
        //     provider = new ethers.providers.Web3Provider(window.ethereum);
        // }

        //today date yyyy-mm-dd
        const today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0')
        const yyyy = today.getFullYear()
        const todayDate = yyyy + '-' + mm + '-' + dd

        //yesterday date yyyy-mm-dd
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        const ydd = String(yesterday.getDate()).padStart(2, '0')
        const ymm = String(yesterday.getMonth() + 1).padStart(2, '0')
        const yyyy2 = yesterday.getFullYear()
        const yesterdayDate = yyyy2 + '-' + ymm + '-' + ydd

        stats = {
            startdate: yesterdayDate,
            enddate: todayDate,
            dailytxnfee: 0,
            dailynetutilization: 0,
            avgdifficulty: 0,
            avgtxnperblock: 0,
            avgtxnperday: 0,
            avgtxnperhour: 0,
            lastBlock: lastBlock,
            lastBlock1: 0,
            avgtxnperminute: 0
        }
        //get stats dailytxnfee
        await axios.get(Config.restAPI + '/api?module=stats&action=dailytxnfee&static=1&apikey=' + Config.ApiKeyToken + '&startdate='+todayDate+'&enddate='+todayDate)
        .then(function (response) {
            if ( response.data.result && response.data.result.length > 0 ) {
                stats.dailytxnfee = response.data.result[0].transactionfee_eth
                console.log('dailytxnfee',response.data.result)
            } else {
                stats.dailytxnfee = 0
                console.log('dailytxnfee',response.data.result)
            }
        })

        await axios.get(Config.restAPI + '/api?module=stats&action=dailynewaddress&static=1&apikey=' + Config.ApiKeyToken + '&startdate='+todayDate+'&enddate='+todayDate)
        .then(function (response) {
            //get the firtst address
            if (response.data.result && response.data.result.length > 0) {
                stats.dailynewaddress = response.data.result[0].newaddresscount
                console.log('dailynewaddress',response.data.result)
            } else {
                stats.dailynewaddress = 0
                console.log('dailynewaddress',response.data.result)
            }
        })

        await axios.get(Config.restAPI + '/api?module=stats&action=dailynetutilization&static=1&apikey=' + Config.ApiKeyToken + '&startdate='+todayDate+'&enddate='+todayDate)
        .then(function (response) {
            if ( response.data.result && response.data.result.length > 0 ) {
                stats.dailynetutilization = response.data.result[0].networkutilization
                console.log('dailynetutilization',response.data.result)
            } else {
                stats.dailynetutilization = 0
                console.log('dailynetutilization',response)
            }
        })

        await axios.get(Config.restAPI + '/api?module=stats&action=dailyavggaslimit&static=1&apikey=' + Config.ApiKeyToken + '&startdate='+todayDate+'&enddate='+todayDate)
        .then(function (response) {
            if ( response.data.result && response.data.result.length > 0 ) {
                stats.dailyavggaslimit = response.data.result[0].avglimit
                console.log('dailyavggaslimit',response.data.result)
            } else {
                stats.dailyavggaslimit = 0
                console.log('dailyavggaslimit',response)
            }

        })

        await axios.get(Config.restAPI + '/api?module=stats&action=dailyavggasprice&static=1&apikey=' + Config.ApiKeyToken + '&startdate='+todayDate+'&enddate='+todayDate)
        .then(function (response) {
            if ( response.data.result && response.data.result.length > 0 ) {
                stats.dailyavggasprice = response.data.result[0].avggaspricewei
                console.log('dailyavggasprice',response.data.result)
            } else {
                stats.dailyavggasprice = 0
                console.log('e-dailyavggasprice',response)
            }

        })

        await axios.get(Config.restAPI + '/api?module=stats&action=dailyavggasprice&static=1&apikey=' + Config.ApiKeyToken + '&startdate='+todayDate+'&enddate='+todayDate)
        .then(function (response) {
            if ( response.data.result && response.data.result.length > 0 ) {
                stats.dailyavggasprice = response.data.result[0].avggaspricewei
                console.log('dailyavggasprice',response.data.result)
            } else {
                stats.dailyavggasprice = 0
                console.log('e-dailyavggasprice',response)
            }
        })

        await axios.get(Config.restAPI + '/api?module=stats&action=dailygasused&static=1&apikey=' + Config.ApiKeyToken + '&startdate='+todayDate+'&enddate='+todayDate)
        .then(function (response) {
            if ( response.data.result && response.data.result.length > 0 ) {
                stats.dailygasused = response.data.result[0].gasused
                console.log('dailygasused',response.data.result)
            } else {
                stats.dailygasused = 0
                console.log('e-dailygasused',response)
            }
        })

        setStats(stats)
    }



    useEffect(() => {
        if (loading) {
         //   getStats()
            getLatest()
            current_Supply()
            setLoading(false)
        } else {
            let timer = setTimeout(() => {
             //   getStats()
                getLatest()
                current_Supply()
              
                getLatestTransactions()
               // insertTransactions()

                if (networkName === 'CoeptIX' && txs.length === 0) {
                  //  getLatestTransactions()
                }

              //  console.log('stats',stats)

            }, 3000);
            return () => clearTimeout(timer)
        }
    })

    stats.lastBlock = lastBlock;
   // console.log("lastBlock1",stats.lastBlock);
    // Render ---------------------------------------------------------------------------------------------------------- //
    return (
        <main style={{ padding: "1rem 0" }} className='app-body'>
            <h4 style={{ textAlign: "start", marginTop:"30px" }} >SIFTO Blockchain Explorer</h4>

            {/* Search Bar */}
            <div style={{ textAlign: "start", margin:"0 0 2rem 0"}}>
                 < SearchBar />
            </div>

            {/* Stats */}
           
             <Dashboard stats={{lastBlock, current_supply}}  />

            {/* Blocks & Transactions */}
            <div className="mt-3">
                <Row >
                    <Col xs={12} md={12} lg={6} xl={6} className="mb-3">
                        <LatestBlocks items={items} />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={6}>
                        <LatestTransactions txs={txs} />
                    </Col>
                </Row>
            </div>

        </main>
    );
}
export default Home