import React from "react";
import { Button, Card, Row, Col, ListGroup } from 'react-bootstrap'
import { Link } from "react-router-dom"

const LatestTransactions = ({txs}) => {
    //console.log("LatestTransactions");
    return (
        <Card>
            <Card.Header className="card-header fw-bold text-start " >Latest Transactions</Card.Header>
            <Card.Body className="std-card-info">
                <ListGroup variant="flush" className="list-group-item">
                    {txs.map((item, idx) => (
                        <ListGroup.Item key={idx}>
                            <Row className="list">
                                <Col md={1} className="col-list">
                                <Link to={`/tx/${item.hash}`}>
                                    {item.status_show === 'Fail' ? (
                                        <Button variant="danger" size="sm" className="ml-2">Tx</Button>
                                    ) : (
                                        <Button variant="success" size="sm" className="ml-2">Tx</Button>
                                    )}
                                    </Link>
                                </Col>
                                <Col md={2} className="col-list">
                                    <Row>
                                    <Link to={`/tx/${item.hash}`} title={item.hash.toString()}>
                                                {item.hash.toString().slice(0, 6) + '...'}
                                     </Link>
                                    </Row>
                                    <Row>
                                        <small>Bk:<Link to={`/block/${item.blocknumber}`}  title={item.blocknumber}>{item.blocknumber}</Link></small>
                                    </Row>
                                </Col>
                                <Col md={5} className="col-list">
                                    <Row>
                                    <Col>
                                        <small>From:</small> 
                                        <Link to={`/address/${item.from_address}`} title={item.from_address}>
                                            {item.from_address ? item.from_address.slice(0, 13) + '...' + (item.from_address.length > 32 ? item.from_address.slice(32, 42) : '') : 'Unknown'}
                                        </Link>
                                    </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            {item.to ? <span><small>To:</small> <Link to={`/address/${item.to}`} title={item.to}>{item.to.slice(0, 13) + '...'+item.to.slice(32, 42)}</Link></span>
                                            : <span>Contract Creation</span>}
                                        </Col>
                                    </Row> */}
                                    <Row>
                                    <Col>
                                        <small>To:</small> 
                                        <Link to={`/address/${item.to_address}`} title={item.to_address}>
                                            {item.to_address ? item.to_address.slice(0, 13) + '...' + (item.to_address.length > 32 ? item.to_address.slice(32, 42) : '') : 'Unknown'}
                                        </Link>
                                    </Col>
                                    </Row>
                                </Col>
                                <Col md={2} className="col-list">
                                    {/* <span>{item.value.toString() / 10 ** 18} SIFTO</span> */}
                                    <span>{item.value} SIFTO</span>
                                    
                                </Col>
                                <Col md={2} className="col-list">
                                    <small>{item.gas / 10 ** 9} gwei</small>
                                    
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                        <ListGroup.Item>
                            <Link to="/txs">
                                <Button variant="secondary morebutton" size="sm" className="ml-2">More transactions</Button>
                            </Link>
                    </ListGroup.Item>
                </ListGroup>
            </Card.Body>
        </Card>
    );
};
export default LatestTransactions;